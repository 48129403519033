import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class SpustiWeb extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Spusti.tv'}
          description={'Prípadová štúdia'}
          text={'Pre Spusti.tv sme vytvorili modernú webovú stránku v štýle microsite. Taktiež sme klientovi vypracovali nakreslenie loga a vypracovali firemnú identitu k projektu.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'logo & branding', link: '/nase-prace/grafika/logo-branding/spustitv-branding' },
            { text: 'ux & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (wordpress)', link: '/nase-prace/webstranky/microsite/spustitv' }
          ]} />

        <AboutProject
          text={'Spolupráca s Spusti.tv prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
        />

        <ProjectShortDescription
          title={'Firemná identita'}
          description={'Pochopenie projektu, jeho zámeru a odborný prístup sú úspechom k plnohodnotnej firemnej identite.'}
          text={'Po konzultáciach s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal nový projekt prezentovať nie len designom, ale aj textami, fotkami a inými komunikačno-vizuálnymi kanálmi.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#008d36', tintColorCode: '#33a45e', nameColor: '#FFFFFF', name: 'North Texas Green' },
          { code: '#f8b133', tintColorCode: '#f9c15c', nameColor: '#FFFFFF', name: 'Yellow Orange' },
          { code: '#1e70b7', tintColorCode: '#4b8dc5', nameColor: '#FFFFFF', name: 'Denim' },
        ]} />

        <FontShowcase
          fontName={'Aller'}
          text={'Founded in 1991 in London, Dalton Maag Ltd provides typeface design, font engineering and font support services for corporate clients and branding agencies. <br/><br/> The skills acquired creating corporate fonts for global clients is translated in building an exclusive font library. All Dalton Maag fonts are finely crafted and all fonts support approx 50 languages using the Latin alphabet with some expanded to include typographic additions as OpenType features.'}
          isFullWidth={false}
          fontExampleSvg={'/images/svg/fonts/aller.svg'}
        />

        <Reveal>
          <div className={'container p-0 mt-big mb-big'}>
            <Img className={'mb-5'} alt={this.constructor.name} fluid={data.corporateIdentity.childImageSharp.fluid} />
          </div>
        </Reveal>

        <PagesShowcase
          title={'UX & Moderný web design'}
          description={'Nová profesionálna tvár Vášho webu'}
          text={'Nový design stránky Spusti.tv zahŕňa všetko, čo by mala profesionálna stránka spĺňať. Jednoduchosť, krása a jedinečnosť sú len jednou časťou, ale tou hlavnou je samotný obsah stránky, ktorý musí byť stredobodom pre uživateľa, aby vypovedal to, čo je potrebné.'}
          projectDetail={'/nase-prace/webstranky/microsite/spustitv'}
          pages={[
            {
              name: 'Hlavná stránka',
              image: data.pageOne,
            },
            {
              name: 'O nás',
              image: data.pageTwo,
            },
            {
              name: 'Kontakt',
              image: data.pageThree,
            },
          ]} />

        {/*        <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="mobilnetelefony" />
          </div>
        </Reveal>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Expandeco'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/microsite/expandeco'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/spusti-tv/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/Spustiweb_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/1_spustiweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/2_spusti_web.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/3_spusti_web.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFour: file(relativePath: { eq: "case-studies/4_spusti_web.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/Spustiweb_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    corporateIdentity: file(relativePath: { eq: "case-studies/Spustiweb_corporate_identity.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default SpustiWeb
